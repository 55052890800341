import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/olynth_ha_05/" className="sel">
              {" "}
              &gt; OLYNTH® PLUS <br />0,5 mg/ml + 50 mg/ml{" "}
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> PLUS <br />0,5 mg/50 mg/ml nosový roztokový sprej{" "}
                    </p>
                    <div className="desc">
                     </div>
                  </div>
                  <div className="photo"> <img src="/web/kcfinder/uploads/files/produkty/sk/Olynth_Pack-Sprej_SK_2023_PLUS_0.5mg_left.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <div>
                    <center><strong>
                    Rýchlo a šetrne uvoľní upchatý nos a podporuje hojenie raniek nosovej sliznice detských noštekov.
                    </strong></center><br />
                      <strong>
                      Nosová sliznica detí počas nádchy potrebuje rýchle a šetrné riešenie. Nádcha 
                      môže spôsobiť vysušenie nosovej sliznice, čo môže následne viesť ku vzniku malých raniek. 
                      Je preto dôležité detský noštek pravidelne čistiť a podporiť proces hojenia.
                      OLYNTH<sup>® </sup>PLUS 0,5 mg/50 mg/ml nosový roztokový sprej rýchlo uvoľní upchatý nos a
                       vďaka dexpantenolu podporuje hojenie malých raniek na sliznici nosa.

                      </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                     <strong>OLYNTH<sup>® </sup>Plus prináša 4 benefity v 1:</strong>
                    </div>
                    <ul>
                      <li>Uvoľňuje upchatý nos</li>
                      <li>Urýchľuje hojenie poranenej nosovej sliznice</li>
                      <li>Chráni nosovú sliznicu</li>
                      <li>Neobsahuje konzervačné látky</li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Dávkovanie a spôsob podávania:</strong><br/>
<span>Tento liek je určený na použitie u detí vo veku 2 – 6 rokov. Odporúčaná dávka je jeden vstrek do každej nosovej dierky až 3-krát denne podľa potreby. V súvislosti s dľžkou používania lieku u detí sa musíte vždy poradiť s lekárom.
</span>
                    </div>
                    
                    <div>&nbsp;</div>
                    
                    <div>
                      <strong>Upozornenie:</strong>
                    </div>
                    <div>
                    Tento liek nepoužívajte dlhšie ako 3 dni. S ďalším použitím lieku sa môže začať
                    len po niekoľkodňovej prestávke. Ak sa dieťa po 3 dňoch nebude cítiť lepšie 
                    alebo sa bude cítiť horšie, porozprávajte sa s lekárom.
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                  </div>
                  <p>
                    <br />
                    <a
                      className="btn_link"
                      href="https://www.sukl.sk/hlavna-stranka/slovenska-verzia/pomocne-stranky/detail-lieku?page_id=386&lie_id=6796C"
                      target="_blank"
                    >
                      PIL - Písomná informácia pre používateľa (link na
                      oficiálne webové stránky ŠÚKL){" "}
                    </a>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/nadcha/ako_si_spravne_cistit_nos/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png)"}} />
                    </div>
                    <div className="c2">
                      Ako si správne čistiť nos pri nádche?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)"}} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/lieky/olynth_ha_05/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
